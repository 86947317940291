import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { Card, TextField, Button, AppBar } from 'bf-ui';

import styles from '../assets/styles/pages/login.page.scss';
import { isPasswordValid, isPhoneValid } from '../helpers/validation.helper';
import { login_req } from '../api/auth.api';
import websiteConfig from '../configs/websiteConfig';
import globalEmitter, { emits } from '../emitter/global.emitter';
import LinkWrapper from '../components/wrappers/link/link.wrapper';

export default function LoginPage() {
  const router = useRouter();
  const intl = useIntl();

  const { id } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState({
    phone: true,
    password: true,
  });

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const onPhoneChange = useCallback(
    (e) => {
      setIsValid({ ...isValid, phone: true });
      setPhone(e.target.value);
    },
    [isValid, phone]
  );

  const onPasswordChange = useCallback(
    (e) => {
      setIsValid({ ...isValid, password: true });

      setPassword(e.target.value);
    },
    [isValid, password]
  );

  useEffect(() => {
    if (id) {
      router.push('/', '/');
    }
  }, []);

  const onLogin = async () => {
    try {
      let notValid = false;

      const valid = {
        phone: true,
        password: true,
      };

      setIsValid(valid);

      if (!isPhoneValid(`${websiteConfig.phoneCode}${phone}`)) {
        notValid = true;
        valid.phone = false;
      }

      if (!isPasswordValid(password)) {
        notValid = true;
        valid.password = false;
      }

      if (notValid) {
        setIsValid(valid);
        return;
      }

      setIsLoading(true);

      const response = await login_req(
        `${websiteConfig.phoneCode}${phone}`,
        password
      );

      globalEmitter.emit(emits.USER_AUTH, {
        user: response.user,
        state: response.state,
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        query: router?.query,
        // freeSpin: response.freespin,
      });
    } catch (e) {
      if (e?.response?.status === 400) {
        globalEmitter.emit(emits.ALERT, {
          type: 'error',
          anchor: 'bottom',
          message: e.response.data.errMsg,
        });
      } else {
        globalEmitter.emit(emits.ALERT, {
          type: 'error',
          anchor: 'bottom',
          message: 'Something went wrong please try later',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div id="login-page" className="page">
        <AppBar className="login_bar">
          <div className="back_wrapper" onClick={() => router.push('/', '/')}>
            <span className="icon-back" />
          </div>
          <h1>{intl.messages.login}</h1>
        </AppBar>
        <div className="login_page_inner">
          <Card>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onLogin();
              }}
            >
              <TextField
                type="number"
                placeholder={intl.messages.phone}
                error={!isValid.phone}
                value={phone}
                onChange={onPhoneChange}
                startAdornment={<span>+{websiteConfig.phoneCode}</span>}
                helperText={
                  !isValid.phone &&
                  intl.formatMessage(
                    {
                      id: 'field_is_not_valid',
                    },
                    {
                      field: intl.messages.phone,
                    }
                  )
                }
              />
              <TextField
                type="password"
                error={!isValid.password}
                placeholder={intl.messages.password}
                value={password}
                onChange={onPasswordChange}
                helperText={
                  !isValid.password &&
                  intl.formatMessage(
                    {
                      id: 'password_hint',
                    },
                    {
                      count: '5',
                    }
                  )
                }
              />
              <Button variant="contained" loading={isLoading}>
                {intl.messages.login}
              </Button>
            </form>
            <LinkWrapper href="/forgot-password" className="forgot">
              {intl.messages.forgot_password}
            </LinkWrapper>
            <div className="register_link">
              <LinkWrapper href="/sign-up">
                {intl.messages.register}
              </LinkWrapper>
            </div>
          </Card>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
}
