import websiteConfig from '../configs/websiteConfig';

export function isPhoneValid(phone, countryCode = websiteConfig.phoneCode) {
  if (!phone) {
    return false;
  }

  if (phone.length !== 12) {
    return false;
  }

  const regex = RegExp(`(${countryCode}){1}[0-9]{9}`, 'g');
  return regex.test(phone);
}

export function isPasswordValid(password = '') {
  const minLength = 5;
  const maxLength = 100;

  if (!password) {
    return false;
  }

  password = `${password}`;

  if (password.length < minLength) {
    return false;
  }

  if (password.length > maxLength) {
    return false;
  }

  return true;
}
